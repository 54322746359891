.App {
  text-align: center;
   
}

.font-link {
  font-family: 'Source Sans Pro', sans-serif;
}

.AppContainer{
    max-width: 1400px;
}

.Header_text{
  align-content: center;
    text-align: center;
    margin-right: 420px;
    margin-top: 0px;
    font-size: 25px;
    color: blue;
}

.userName{
  padding-left: 75%;
  padding-top: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Home-bg{
    background-image:url(./images/Current-Contractor-White.svg);
    background-repeat: no-repeat;
    background-size: 40% 105%;
    margin-left: 8%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.topheight{
  padding-top: 63px;
}

.subHeader{
  padding-top: 10px;
  color: slateblue;
}

.holds-the-iframe {
  background:url(./images/Spinner-3.gif) center center no-repeat;
}

.wel{
  padding-top: 8px;
}
