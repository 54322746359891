@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
* {
  box-sizing: border-box;
}
.bodyBlue {
  background: #00416b !important;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin-left: 60%;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-inner-text {
  width: 800px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.textColor {
  color: black;
  padding: 5%;
}

.logoSize {
  padding: 5px;
  margin-left: -30px;
  width: 150px;
}

.App {
  text-align: center;
   
}

.font-link {
  font-family: 'Source Sans Pro', sans-serif;
}

.AppContainer{
    max-width: 1400px;
}

.Header_text{
  -webkit-align-content: center;
          align-content: center;
    text-align: center;
    margin-right: 420px;
    margin-top: 0px;
    font-size: 25px;
    color: blue;
}

.userName{
  padding-left: 75%;
  padding-top: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Home-bg{
    background-image:url(/static/media/Current-Contractor-White.37eee666.svg);
    background-repeat: no-repeat;
    background-size: 40% 105%;
    margin-left: 8%;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.topheight{
  padding-top: 63px;
}

.subHeader{
  padding-top: 10px;
  color: slateblue;
}

.holds-the-iframe {
  background:url(/static/media/Spinner-3.b51df3f3.gif) center center no-repeat;
}

.wel{
  padding-top: 8px;
}

